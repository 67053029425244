.closer
  position: absolute
  display: flex
  justify-content: center
  align-items: center
  top: 0
  right: 0
  width: 32px
  height: 32px
  border-radius: 0 4px 0 16px
  cursor: pointer

  &_light-blue
    background: $bg

    @media (hover)
      &:hover
        & > .icon
          fill: $accent

  &_transparent
    background: rgba(0, 0, 0, .1)

    & > .icon
      fill: $invert

    @media (hover)
      &:hover
        & > .icon
          fill: $accent

  &_true-transparent
    background: transparent

    @media (hover)
      &:hover
        & > .icon
          fill: $accent
