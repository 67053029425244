.react-code-input
  display: flex !important
  gap: 8px

  input
    +field
    width: 32px
    text-align: center
    text-transform: uppercase

.dark-theme
  .react-code-input
    input
      +dark-field
