.dark-theme
  .field
    background-color: #67777e
    border-color: #939B9f
    color: $invert

    &:focus
      border-color: $main

    &_active
      border-color: $main

    &_error
      border-color: $accent

    &:not(&_active):not(&_error)
      border-color: #939B9f

      &:focus
        border-color: $main

    &::placeholder
      color: #d9d9d9
