.notification-list
  position: fixed
  bottom: 32px
  left: 32px
  display: grid
  justify-items: start
  grid-row-gap: 16px
  max-width: 256px
  z-index: 1

  &__item
    position: relative
    padding: 16px 48px 16px 16px
    background: $accent
    border-radius: 8px
    box-shadow: $shadow
    animation: fadeIn $transition-options forwards

    &_closing
      animation: fadeOut $transition-options forwards
