.popup
  width: 100%
  display: grid
  justify-items: center
  align-items: center

  &_bg
    position: fixed
    top: 0
    left: 0
    height: 100%
    padding: 16px
    background: $overlay
    z-index: 1
    +scrollbar-wide

  &__wrapper
    width: 100%
    max-width: 370px
    padding: 16px
    background: $invert
    border-radius: 4px
    box-shadow: $shadow

    &_wide
      max-width: 544px

    &_extra-wide
      max-width: 600px

    &_max-wide
      max-width: 790px
      padding: 32px

    & > .title_h2
      &:first-of-type
        padding-right: 32px
