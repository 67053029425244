.progress-bar
  width: 100%
  height: 8px
  background: $bg
  border-radius: 4px

  @media #{$mobile}
    max-width: 100%

  &__thumb
    height: 100%
    background: $gradient-spectrum
    border-radius: inherit
    transition: width $transition-options
