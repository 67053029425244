.dropzone
  display: flex
  flex-direction: column
  align-items: center
  max-width: 272px
  padding: 24px
  border: 1px dashed $main
  border-radius: 4px
  text-align: center
  transition: border-color $transition-options
  cursor: pointer

  @mixin hover
    border-color: $accent

    & > .icon
      fill: $accent

  @media (hover)
    &:hover
      +hover

  @media #{$mobile}
    max-width: 100%

  &_active
    +hover

  &_max
    max-width: initial
    width: 100%
