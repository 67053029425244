.dark-theme
  .switch
    background: #374c52
    border-color: $main

    @media (hover)
      &:hover
        background: #4a6972

    &_active
      background: #4a6972
