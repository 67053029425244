.dark-theme
  .tariff-plan
    background: linear-gradient(180deg, #505759 0%, #364b52 100%)

    &:not(&_selected)
      border-color: #67777e

    &__options
      &-item
        background: linear-gradient(180deg, #505759 0%, #364b52 100%)

        &:not(&_selected)
          border-color: #67777e

          @media (hover)
            &:hover
              border-color: $optional
