.table
  width: 100%
  border-collapse: collapse
  border-bottom: 1px solid $border

  &_embedded
    & > tbody
      & > .table__row
        &:last-of-type
          border-bottom: none

  &__container
    +scrollbar

  &__head
    height: 32px
    background: $bg

    .table__cell
      height: 32px
      color: $text-optional
      font-size: .75rem
      font-weight: 400
      text-align: left

  &__row

    &_collapsible:not(&_open)
      & > .table__cell
        padding: 0 8px

    &:not(:first-of-type):not(&_collapsible)
      border-top: 1px solid $border

    &:not(&_collapsible)
      height: 48px

    .table__cell
      font-size: .875rem

  &__cell
    padding: 8px
    transition: padding $transition-options

    &_icon
      width: 32px
      padding: 0

  .table
    border-bottom: none
