.icon
  flex-shrink: 0
  fill: $main
  transition: fill $transition-options, transform $transition-options

  &_light
    fill: $text-light

  &_rotate
    transform: rotate(180deg)

  &_spinner
    & > path
      transform-origin: center

      &:first-child
        animation: spinRight 1.5s infinite linear

      &:last-child
        animation: spinLeft 1s infinite linear

      @keyframes spinLeft
        0%
          transform: rotate(0deg)

        100%
          transform: rotate(360deg)

      @keyframes spinRight
        0%
          transform: rotate(0deg)

        100%
          transform: rotate(-360deg)
