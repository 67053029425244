.dark-theme
  .btn

    &_white
      background: #374c52
      border-color: $main
      color: $main

      @media (hover)
        &:hover
          background: #4a6972

      & > .icon
        fill: $main

    &_transparent
      color: $invert

      @media (hover)
        &:hover
          & > .icon
            fill: $main
