.page
  display: flex
  flex-direction: column
  flex-grow: 1
  padding: 16px
  background: $invert

  &_center
    display: flex
    justify-content: center
    align-items: center

  &_transparent
    background: transparent !important
