.line
  width: 100%
  height: 1px
  margin: 16px 0
  background: $border

  &_full-width

    &-12
      width: calc(100% + 24px)
      margin-left: -12px !important
