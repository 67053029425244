*
  margin: 0
  font-family: Roboto, helvetica, arial, sans-serif
  box-sizing: border-box

html, body, #root
  height: 100%

body
  +scrollbar-wide

a
  text-decoration: none

button
  border: none

input
  outline: none

.position-relative
  position: relative

.smart-container
  position: relative
  width: min-content
