.tooltip
  display: flex

  &__container
    position: absolute
    top: 0
    left: 0

  &__wrapper
    position: absolute
    width: max-content
    max-width: 288px
    padding: 16px
    background: $invert
    box-shadow: $shadow
    border-radius: 2px
    opacity: 1
    transition: visibility $transition-options, opacity $transition-options
    z-index: 1

    &:not(&_shown)
      visibility: hidden
      opacity: 0
