.dark-theme
  .toggle
    &__slider
      background: #67777e

  input
    &:not(:checked)
      & ~ .toggle__slider
        &::after
          background: #d9d9d9
