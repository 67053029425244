.text
  max-width: 100%
  color: $text-optional
  font-size: .875rem
  line-height: 1rem
  transition: color $transition-options

  &_max-height
    +scrollbar

  &_s
    font-size: .75rem

  &_white
    color: $invert

  &_dark
    color: $text-main

  &_light
    color: $text-light

  &_bold
    font-weight: 500

  &_dedicated
    width: max-content
    padding: 8px 16px
    background: $bg
    border-radius: 4px
    word-break: break-all

  &_crossing
    text-decoration: line-through

  &_version
    position: absolute
    top: 8px
    left: 16px
