.radio
  width: fit-content
  cursor: pointer

  &__button
    width: 20px
    height: 20px
    border: 1px solid $border
    border-radius: 50%
    transition: border $transition-options

  input[type='radio']
    display: none

    &:checked ~ .radio__button
      border: 4px solid $main
