.dark-theme
  .method
    background: #696e70
    border-color: transparent
    box-shadow: none

    @mixin active
      border-color: $optional
      box-shadow: 0px 14px 28px rgba(0, 0, 0, .3)

    &:not(&_selected)

      @media (hover)
        &:hover
          +active

    &_selected
      +active
