.field
  width: 100%
  height: 32px
  padding: 0 8px
  border: 1px solid $border
  border-radius: 2px
  color: $text-main
  font-size: .875rem
  line-height: 1rem
  transition: border-color $transition-options
  outline: none

  &:focus
    border-color: $main

  &_active
    border-color: $main

  &_error
    border-color: $accent

  &_min
    width: 132px

  &_max
    width: 100%

  &::placeholder
    color: $text-optional
