.article

  @media #{$laptop}
    width: 60%

  @media #{$tablet}
    width: 80%

  @media #{$mobile}
    width: 100%

  @mixin wide
    width: 60%

    @media #{$laptop}
      width: 80%

    @media #{$tablet}
      width: 100%

  &_wide, .popup &
    +wide
