.wrapper
  display: flex
  flex-wrap: wrap
  max-width: 100%

  &_column
    flex-direction: column

  &_nowrap
    flex-wrap: nowrap

  &_justify-content

    &-space-between
      justify-content: space-between

  &_align-items

    &-flex-start
      align-items: flex-start

    &-center
      align-items: center

  &_gap

    &-0
      gap: 0

    &-3
      gap: 3.5px

    &-4
      gap: 4px

    &-8
      gap: 8px

    &-12
      gap: 12px

    &-16
      gap: 16px

    &-24
      gap: 24px

    &-32
      gap: 32px

  &_width
    &-max-content
      width: max-content

  &_clickable
    cursor: pointer

    @media (hover)
      &:hover
        & > .icon
          fill: $accent
