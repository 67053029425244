.dark-theme
  .table
    border-color: #67777e

    &__head
      background: #586367

      & > .table__cell
        color: $invert

    &__row
      border-color: #67777e !important

      & > .table__cell
        color: #D9D9D9
