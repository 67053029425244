.dark-theme
  .select

    &__field
      background-image: url('../#{$path}/img/icons/angle-down_white.svg')
      color: $invert

      &:not(&_disabled)
        @media (hover)
          &:hover
            background-image: url('../#{$path}/img/icons/angle-down.svg')

    &__placeholder
      color: #d9d9d9

    &__dropdown
      background: linear-gradient(180deg, #505759 0%, #364b52 100%)
      box-shadow: 0 14px 28px rgba(0, 0, 0, .3)

      &-search
        background: #586366

    &__options

      &-item

        &_selected
          background: #526c77

        &:not(&_selected)
          @media (hover)
            &:hover
              background: #526c77
