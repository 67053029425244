.tariff-plan
  flex: 1 0
  border: 2px solid $border
  border-radius: 4px
  transition: border-color $transition-options

  &_start
    &.tariff-plan_selected
      border-color: $optional

    .tariff-plan__head
      background: $optional

  &_pro
    &.tariff-plan_selected
      border-color: $accent

    .tariff-plan__head
      background: $accent

  &__head
    position: relative
    top: -2px
    left: -2px
    display: flex
    justify-content: space-between
    align-items: center
    width: calc(100% + 4px)
    padding: 16px 24px
    border-radius: 4px 4px 0 0

    .title
      color: $invert

  &__body
    padding: 16px

  &__options
    display: grid
    grid-template-columns: repeat(2, 1fr)
    grid-gap: 16px 16px

    @media #{$mobile}
      grid-template-columns: 1fr

    &-item
      position: relative
      display: flex
      flex-direction: column
      align-items: center
      padding: 16px
      border: 1px solid $border
      border-radius: 4px
      transition: border-color $transition-options, box-shadow $transition-options
      text-align: center

      &::after
        content: ''
        position: absolute
        bottom: -18px
        width: 32px
        height: 32px
        background: url('#{$path}/img/icons/tariff-check.svg') no-repeat center / contain
        visibility: hidden
        opacity: 0
        transition: visibility $transition-options, opacity $transition-options

      @mixin active
        border-color: $optional
        box-shadow: 0 0 1px rgba(0, 45, 77, .3), 0 3px 4px rgba(0, 45, 77, .05)

      &_selected
        +active

        &::after
          visibility: visible
          opacity: 1

      &:not(&_selected)
        cursor: pointer

        @media (hover)
          &:hover
            +active
