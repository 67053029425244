.checkbox
  flex-shrink: 0
  width: 20px
  height: 20px
  background: $invert
  border: 1px solid $border
  border-radius: 2px
  cursor: pointer
  transition: background $transition-options, border-color $transition-options

  &__label
    display: block
    width: fit-content
    max-width: 272px
    transition: opacity $transition-options
    cursor: pointer

    &_disabled
      opacity: .6
      cursor: default

    input[type='checkbox']
      display: none

      &:checked ~ .checkbox
        background: url('#{$path}/img/icons/check.svg') no-repeat center, $main
        border-color: $main

      &:disabled ~ .checkbox
        cursor: default
