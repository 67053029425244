.textarea
  padding: 8px
  max-width: 272px
  min-height: 66px
  max-height: 272px
  +scrollbar

  @media #{$mobile}
    max-width: 100%

  &_max
    max-width: 100%
