.body
  display: flex
  flex-direction: column
  width: 100%
  max-width: 1032px
  margin: 0 auto
  padding: 32px 16px 40px
  flex-grow: 1

  &_center
    justify-content: center
