.btn
  display: flex
  align-items: center
  width: max-content
  height: 32px
  padding: 0px 16px
  border-radius: 4px
  font-size: .875rem
  font-weight: 500
  line-height: 1rem

  &:not(:disabled)
    cursor: pointer

  &:disabled
    opacity: .5

  &_blue
    background: $main
    color: $invert
    transition: background $transition-options, box-shadow $transition-options, opacity $transition-options

    & > .icon
      fill: $invert

    &:not(:disabled)
      @media (hover)
        &:hover
          background: $accent
          box-shadow: 0 8px 16px rgba(255, 161, 66, .3), inset 0 -8px 12px rgba(255, 167, 67, .7)

  &_orange
    background: $accent
    color: $invert
    transition: box-shadow $transition-options

    @media (hover)
      &:hover
        box-shadow: 0 8px 16px rgba(255, 161, 66, .3), inset 0 -8px 12px rgba(255, 167, 67, .7)

  &_white
    background: $invert
    border: 1px solid $border
    color: $main
    transition: background $transition-options, border-color $transition-options, color $transition-options

    @media (hover)
      &:hover
        border-color: $accent
        color: $accent

        & > .icon
          fill: $accent

  &_transparent
    padding: 0
    background: transparent
    color: $text-main
    font-weight: 400
    transition: opacity $transition-options

    &:not(:disabled)
      @media (hover)
        &:hover
          & > .icon
            fill: $accent

  &_with-icon
    padding-left: 0
