.shift
  display: flex
  border-radius: 4px
  +scrollbar

  &__button
    padding: 7.75px 8px
    background: $bg
    transition: background $transition-options
    white-space: nowrap
    cursor: pointer

    & > .text
      color: $text-main

    @mixin active
      background: $main

      & > .text
        color: $invert

    @media (hover)
      &:hover
        +active

    &_active
      +active
      cursor: auto
