.notice-list
  position: fixed
  right: 0
  display: grid
  grid-row-gap: 16px
  width: 316px
  margin: -16px -272px 0 0
  z-index: 0

  &__container
    width: 316px
    margin-left: 0
    padding-right: 16px
    transition: margin-left $transition-options

    &:hover
      margin-left: -272px

  &__item
    position: relative
    display: flex
    flex-direction: column
    justify-content: space-between
    padding: 16px 16px 16px 44px
    background: $invert
    box-shadow: $shadow
    border-radius: 4px

    .title_h3
      max-width: calc(100% - 16px)
      color: $main

    .text
      white-space: break-spaces

    &_warning
      .title_h3
        color: $accent

    &_danger
      .title_h3
        color: #ff5935

    &_success
      .title_h3
        color: #88c734

  &__icon
    position: absolute
    top: 13px
    left: 12px
    width: 20px
    height: 20px
    background: url('#{$path}/img/notice/info.svg') no-repeat center / contain

    &_warning
      background-image: url('#{$path}/img/notice/warning.svg')

    &_danger
      background-image: url('#{$path}/img/notice/danger.svg')

    &_success
      background-image: url('#{$path}/img/notice/success.svg')
