.dark-theme
  .text
    color: #d9d9d9

    &_white
      color: $invert

    &_dark
      color: $invert

    &_light
      color: #67777e

    &_dedicated
      background: #556165
