.dark-theme
  .closer
    &_light-blue
      background: #556165

      @media (hover)
        &:hover
          & > .icon
            fill: $main

    &_true-transparent
      @media (hover)
        &:hover
          & > .icon
            fill: $main
