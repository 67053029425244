.grid
  display: grid
  grid-template-columns: repeat(3, 1fr)
  width: 100%

  &_2
    grid-template-columns: repeat(2, 1fr)

  @media #{$laptop}
    grid-template-columns: repeat(2, 1fr)

  @media #{$tablet}
    grid-template-columns: 1fr

  &_auto
    grid-template-columns: repeat(auto-fill, minmax(134px, 1fr))

  &_gap
    &-8
      grid-gap: 8px

    &-12
      grid-gap: 12px

    &-16
      grid-gap: 16px

    &-24-56
      grid-gap: 24px 56px

  &_width
    &-max-content
      width: max-content
