.nav

  &__bar
    display: flex
    flex-wrap: wrap
    max-width: 100%
    gap: 4px 16px
    +scrollbar

  &__item
    padding-bottom: 7px
    border-bottom: 2px solid transparent
    transition: border-color $transition-options

    @media (hover)
      &:hover
        border-color: $main

    &_link-like
      .text
        color: $main

    &_active
      border-color: $main

      .text
        color: $main

    &_without-underscore
      border-color: transparent

    .text
      white-space: nowrap

    &:not(&_active)
      cursor: pointer

  &__tab
    display: none
    transition: display $transition-options

    &_shown
      display: initial
