.variable-list
  display: flex
  flex-wrap: wrap
  gap: 4px

  &__item
    padding: 4px 8px
    background: $bg
    border-radius: 1px
    color: $main
    transition: color $transition-options, background $transition-options
    cursor: pointer

    @media (hover)
      &:hover
        color: $accent
