.dark-theme
  .header
    background: linear-gradient(180deg, #0f161c 0%, #20323f 100%)
    box-shadow: none

    &__logo
      background-image: url('../#{$path}/img/logo.jpg')

      @media #{$mobile}
        background-image: url('../#{$path}/img/logo.jpg')
