.dark-theme
  .nav
    &__item
      @media (hover)
        &:hover
          border-color: $invert

      &_active
        border-color: $invert

        .text
          color: $invert

      &:not(&_active)
        .text
          color: #939b9f !important

      &_without-underscore
        border-color: transparent

        span
          color: #939b9f !important
