.connection
  display: none
  padding: 16px
  background: $invert
  border: 1px solid $border
  border-radius: 4px

  &_visible
    display: initial

  &__inner
    display: flex
    height: 100%

  &__aside
    overflow: hidden

    &-slider
      height: 100%
      margin-right: 12px
      padding-right: 12px
      border-right: 1px solid $border
      margin-left: -45px
      transition: margin-left $transition-options

      &_shown
        margin-left: 0

  &__content
    display: flex
    flex-direction: column
    justify-content: space-between
    width: 100%

  &__wrapper
    display: flex
    flex-direction: column
    gap: 12px
