.dark-theme
  .section
    background: linear-gradient(180deg, #505759 0%, #364b52 100%)
    box-shadow: none

    &__head
      background: #586367

      @media (hover)
        &:hover
          .icon
            fill: $main

    &__body
      background: linear-gradient(180deg, #505759 0%, #364b52 100%)
      border-top: none
